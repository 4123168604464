<template>
    <div>
      <c-search-box @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- 사업장 -->
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :range="true"
              defaultStart="-1M"
              defaultEnd="1M"
              label="작성기간"
              name="period"
              v-model="searchParam.period"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              :comboItems="statusItems"
              type="search"
              itemText="codeName"
              itemValue="code"
              name="completeFlag"
              label="작성상태"
              v-model="searchParam.completeFlag"
            ></c-select>
          </div>
        </template>
      </c-search-box>
      <c-table
        ref="table"
        title="산업보건 일지 목록"
        tableId="table"
        :merge="grid.merge"
        :columns="grid.columns"
        :data="grid.data"
        @linkClick="linkClick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <div>
            <q-btn-group outline>
              <c-btn v-if="editable" label="등록" icon="add" @btnClicked="linkClick" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </div>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="props.row[col.name] > 0">
            {{ props.row[col.name] }}
          </template>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </template>
  
  <script>
  import selectConfig from '@/js/selectConfig';
  export default {
    name: 'industry-health-journal',
    data() {
      return {
        searchParam: {
          plantCd: null,
          completeFalg: null,
          period: [],
        },
        grid: {
          merge: [
            { index: 0, colName: 'industryHealthJournalId' },
            { index: 1, colName: 'industryHealthJournalId' },
            { index: 2, colName: 'industryHealthJournalId' },
            { index: 3, colName: 'industryHealthJournalId' },
            { index: 4, colName: 'industryHealthJournalId' },
          ],
          columns: [
            {
              name: 'plantName',
              field: 'plantName',
              label: '사업장',
              style: 'width:100px',
              align: 'center',
              fix: true,
              sortable: true,
            },
            {
              name: 'completeFlagName',
              field: 'completeFlagName',
              label: '작성상태',
              align: 'center',
              fix: true,
              sortable: true,
              style: 'width:100px',
            },
            {
              name: 'approvalStatusName',
              field: 'approvalStatusName',
              label: '결재진행단계',
              align: 'center',
              fix: true,
              sortable: true,
              style: 'width:100px',
            },
            {
              name: 'startDate',
              field: 'startDate',
              label: '작성일',
              style: 'width:100px',
              fix: true,
              align: 'center',
              sortable: true,
            },
            {
              name: 'journalName',
              field: 'journalName',
              label: '일지명',
              style: 'width:300px',
              align: 'left',
              fix: true,
              type: 'link',
              sortable: false,
            },
            {
              name: 'sexTypeName',
              field: 'sexTypeName',
              label: '구분',
              style: 'width:60px',
              align: 'center',
              sortable: false,
            },
            {
              name: 'code1',
              field: 'code1',
              label: '소화기',
              style: 'width:60px',
              type: 'custom',
              align: 'center',
              sortable: false,
            },
            {
              name: 'code2',
              field: 'code2',
              label: '호흡기',
              style: 'width:60px',
              type: 'custom',
              align: 'center',
              sortable: false,
            },
            {
              name: 'code3',
              field: 'code3',
              label: '근골',
              style: 'width:60px',
              type: 'custom',
              align: 'center',
              sortable: false,
            },
            {
              name: 'code4',
              field: 'code4',
              label: '신경',
              style: 'width:60px',
              type: 'custom',
              align: 'center',
              sortable: false,
            },
            {
              name: 'code5',
              field: 'code5',
              label: '외상',
              style: 'width:60px',
              type: 'custom',
              align: 'center',
              sortable: false,
            },
            {
              name: 'code6',
              field: 'code6',
              label: '화상',
              style: 'width:60px',
              type: 'custom',
              align: 'center',
              sortable: false,
            },
            {
              name: 'code7',
              field: 'code7',
              label: '안구',
              style: 'width:60px',
              type: 'custom',
              align: 'center',
              sortable: false,
            },
            {
              name: 'code8',
              field: 'code8',
              label: '물리치료',
              style: 'width:60px',
              type: 'custom',
              align: 'center',
              sortable: false,
            },
            {
              name: 'code9',
              field: 'code9',
              label: '기타',
              style: 'width:60px',
              type: 'custom',
              align: 'center',
              sortable: false,
            },
            {
              name: 'total',
              field: 'total',
              label: '합계',
              style: 'width:60px',
              align: 'center',
              sortable: false,
            },
          ],
          data: [],
        },
        editable: true,
        listUrl: '',
        statusItems: [
          { code: 'N', codeName: '작성중' },
          { code: 'Y', codeName: '작성완료' },
        ],
        popupOptions: {
          target: null,
          title: '',
          isFull: true,
          visible: false,
          top: '',
          param: {},
          closeCallback: null,
        },
      };
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {},
    methods: {
      init() {
        // role setting
        this.editable = this.$route.meta.editable;
        // url setting
        this.listUrl = selectConfig.hea.ihj.list.url;
        // code setting
        // list setting
        this.getList();
      },
      getList() {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      },
      linkClick(row) {
        this.popupOptions.title = "산업보건 일지 상세"; 
        this.popupOptions.param = {
          industryHealthJournalId: row ? row.industryHealthJournalId : '',
        };
        this.popupOptions.target = () => import(`${"./industryHealthJournalDetail.vue"}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      },
      closePopup() {
        this.popupOptions.target = null;
        this.popupOptions.visible = false;
        this.getList();
      },
    },
  };
  </script>
  